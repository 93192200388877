<template>
  <layout-horizontal>
    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-horizontal>
</template>

<script>
  import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
  import { $themeConfig } from '@themeConfig';

  export default {
    components: {
      LayoutHorizontal,
    },
    data() {
      return {
        showCustomizer: $themeConfig.layout.customizer,
      };
    },
  };
</script>
