/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard';
// import courseIntroduction from './course-introduction';
// import assosiationIntroduction from './assosiation-introduction';
// import friendsBook from './friends-book';
// import friendsNews from './friends-news';
// import formAndTable from './forms-and-table';

// Array of sections
export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  // *===============================================---*
  // *--------- 과정소개
  // *===============================================---*
  {
    header: 'Course Introduction',
  },
  {
    title: 'Open Purpose',
    route: { name: 'open-purpose', params: { id: 4987 } },
    icon: 'InfoIcon',
  },
  {
    title: 'Course Curriculum',
    route: { name: 'course-curriculum', params: { id: 4987 } },
    icon: 'LayersIcon',
  },
  {
    title: 'Organization Chart',
    route: { name: 'organization-chart', params: { id: 4987 } },
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Director Chart',
    route: { name: 'director-chart' },
    icon: 'UserCheckIcon',
  },
  {
    title: 'Committee Member',
    route: { name: 'committee-member', params: { id: 4987 } },
    icon: 'UsersIcon',
  },
  {
    title: 'Location Map',
    route: { name: 'location-map', params: { id: 4987 } },
    icon: 'MapIcon',
  },
  // *===============================================---*
  // *--------- 총동문회 소개
  // *===============================================---*

  {
    header: 'Association Introduction',
  },
  {
    title: 'Presidency List',
    route: { name: 'presidency-list', params: { id: 4987 } },
    icon: 'UserCheckIcon',
  },
  {
    title: 'Association Chart',
    route: { name: 'association-chart', params: { id: 4987 } },
    icon: 'GitPullRequestIcon',
  },
  {
    title: 'Association Presidency',
    route: { name: 'association-presidency', params: { id: 4987 } },
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Presidency of Gens',
    route: { name: 'gens-presidency', params: { bid: 7 } },
    icon: 'UserCheckIcon',
  },
  {
    title: 'Association Rule',
    route: { name: 'association-rule', params: { id: 4987 } },
    icon: 'FileTextIcon',
  },
  // *===============================================---*
  // *--------- 원우수첩
  // *===============================================---*
  {
    header: 'Friends',
  },
  {
    title: 'Friends Book',
    route: 'friends-book',
    icon: 'BookOpenIcon',
  },
  // *===============================================---*
  // *--------- 원우소식
  // *===============================================---*
  {
    header: 'Firends Notification',
  },
  {
    title: 'Announcement',
    route: 'announcement',
    icon: 'BellIcon',
  },
  {
    title: 'Friends News',
    route: 'friends-news',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'Event Gallery',
    route: 'event-gallery',
    icon: 'ImageIcon',
  },
  {
    title: 'Event Schedule',
    route: 'event-schedule',
    icon: 'CalendarIcon',
  },
  {
    title: 'Book Usage',
    route: { name: 'menu-book-usage', params: { id: 4987 } },
    icon: 'CheckSquareIcon',
  },
];
